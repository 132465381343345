import NavButton from "../../components/buttons/navButton/NavButton"
import { validEngineeringLinks } from "../../helperFunctions/regex"
import Header from "../../components/header"
import topics from "../../searchData.json"
 

function EngineeringHomepage(){
	const linkStructure = <> &rarr; Engineering </>

	return(
		<>
			<Header linkStructure={ linkStructure }/>
			<div className='grid grid-cols-1 md:grid-cols-2'>
				{ topics.filter(element => {
					return validEngineeringLinks.test(element.url) === true
				})
				.map(element => {
					return <NavButton key={element.topic} name={element.topic} link={element.url} color={element.color} />
				})}
			</div>
		</>
	)
}

export default EngineeringHomepage