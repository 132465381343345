import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function NavButton({ name, link, color }) {
  const [buttonName, setButtonName] = useState(name);
  const buttonStyling = `w-full m-2 rounded text-2xl text-center p-4 ${color} `; //Keep space to not ruin next styling

  const handleMouseOver = () => {
    setButtonName(`${name} >>>`);
  };

  const handleMouseOut = () => {
    setButtonName(`${name}`);
  };
  return (
    <div className="flex"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <Link className={buttonStyling} to={link}>
        <button
          className={buttonStyling}
          data-testid="navigationButton"
        >
          {buttonName}
        </button>
      </Link>
    </div>
  );
}
