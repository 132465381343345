import { Routes, Route } from 'react-router-dom';
import './App.css'
import Homepage from './pages/Homepages/Homepage';
// Math Imports
import MathHomepage from './pages/Homepages/MathHomepage';
import AlgebraPage from './pages/Math/AlgebraPage';
import CalculusPage from './pages/Math/CalculusPage'
import TrigonometryPage from './pages/Math/TrigonometryPage'
// Computer Science Imports
import ComputerScienceHomepage from './pages/Homepages/ComputerScienceHomepage';
// Engineering Imports
import EngineeringHomepage from './pages/Homepages/EngineeringHomepage';
// Physics Imports
import PhysicsHomepage from './pages/Homepages/PhysicsHomepage';
// Biology Imports
import BiologyHomepage from './pages/Homepages/BiologyHomepage';
// Chemistry Imports
import ChemistryHomepage from './pages/Homepages/ChemistryHomepage';

function App() {
  return (
		<div className='mx-auto p-4'>
			<Routes>
				<Route path='/' element={<Homepage />} />
				<Route path='/math' element={<MathHomepage />} />
				<Route path='/math/algebra' element={<AlgebraPage />} />
				<Route path='/math/calculus' element={<CalculusPage />} />
				<Route path='/math/trigonometry' element={<TrigonometryPage />} />

				<Route
					path='/computer-science'
					element={<ComputerScienceHomepage />}
				/>

				<Route path='/engineering' element={<EngineeringHomepage />} />

				<Route path='/physics' element={<PhysicsHomepage />} />

				<Route path='/biology' element={<BiologyHomepage />} />

				<Route path='/chemistry' element={<ChemistryHomepage />} />
			</Routes>
		</div>
  )
}

export default App;
