import { Link } from 'react-router-dom'
import Header from '../../components/header'

export default function CalculusPage() {
	const linkStructure = (
		<>
			{' '}
			&rarr; <Link to='/math'>Math</Link> &rarr; Calculus
		</>
	)
	return (
		<>
			<Header linkStructure={linkStructure} />
		</>
	)
}
