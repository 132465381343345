/*
    Regular expressions used to match the correct topics for their respective pages based on the url
*/

// Main Homepage
export const validHomepageLinks = new RegExp('^/([a-z-A-Z]+)$')

// Second order homepage links
export const validMathLinks = new RegExp('^/math/([a-zA-Z]+)$')

export const validComputerScienceLinks = new RegExp('^/computer-science/([a-zA-Z]+)$')

export const validEngineeringLinks = new RegExp('^/engineering/([a-zA-Z]+)$')

export const validPhysicsLinks = new RegExp('^/physics/([a-zA-Z]+)$')

export const validChemistryLinks = new RegExp('^/chemistry/([a-zA-Z]+)$')

export const validBiologyLinks = new RegExp('^/biology/([a-zA-Z]+)$')

// Third order homepage links
