import { Link } from "react-router-dom";
import Header from "../../components/header";

export default function AlgebraPage() {
    const linkStructure = <> &rarr; <Link to='/math'>Math</Link> &rarr; Algebra</>
    return(
        <>
            <Header linkStructure={ linkStructure }/>

        </>
    )
}