import NavButton from '../../components/buttons/navButton/NavButton';
import Header from '../../components/header';
import { validHomepageLinks } from '../../helperFunctions/regex'
import topics from '../../searchData.json'

function Homepage() {
	return (
		<>
			<Header />
			<div className='grid grid-cols-1 md:grid-cols-2'>
				{ topics.filter(element => {
						return validHomepageLinks.test(element.url) === true
					})
					.map(element => {
						return <NavButton key={element.topic} name={element.topic} link={element.url} color={element.color} />
					})}
			</div>
		</>
	);
}

export default Homepage;
