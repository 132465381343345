import React, { useState } from 'react';
import Select from 'react-select'
import { Link, useNavigate } from 'react-router-dom';
import topics from '../searchData.json'


export default function Header({ linkStructure }) {

	const navigate = useNavigate()
	const [selectedOption, setSelectedOption] = useState(null)
	const searchTopics = topics.map(({ topic, url }) => {
		return{
			value: url,
			label: topic
		}
	})

	const handleChange = (newOption) => {
		setSelectedOption(newOption)
		navigate(`${newOption.value}`)
	}

	return (
		<>
			<div className='grid grid-cols-1 md:grid-cols-2'>
				<div className='grid'>
					<h1 className='hidden md:block text-4xl text-white pb-2'>
						<Link to='/'>Stem Calc</Link>
						{linkStructure}
					</h1>
					<h1 className='md:hidden text-4xl text-white pb-2'>
						<Link to='/'>Stem Calc</Link>
					</h1>
					<p className='text-white pb-2'>
						A calculator for all your <strong>S</strong>(cience){' '}
						<strong>T</strong>(ech) <strong>E</strong>(ngineering){' '}
						<strong>M</strong>(ath) needs
					</p>
				</div>
				<div className='grid content-center justify-items-end'>
					<Select
						className='w-full md:w-1/2'
						value={selectedOption}
						options={searchTopics.sort((option1, option2) => {
							var topic1 = option1.label
							var topic2 = option2.label

							if (topic1 > topic2) return 1
							if (topic1 < topic2) return -1
							return 0
						})}
						onChange={handleChange}
						placeholder='Search Topics...'
						openMenuOnClick={false}
					/>
				</div>
			</div>
			<br className='md:hidden'></br>{' '}
			{/* Only show the break on mobile devices */}
			<hr className='pb-2'></hr>
		</>
	)
}
